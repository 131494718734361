/* eslint-disable */

import React, { useRef, useEffect } from 'react';
import useTickerControl from '../hooks/tickerControl';

import * as tmpPIXI from 'pixi.js-legacy';
global.PIXI = tmpPIXI;
const PIXI = global.PIXI;


class SpeakerController {
    constructor(canvas) {
        this._canvas = canvas;

        this._hasLoaded = false;

        this._targetX = 0;
        this._targetY = 0;
        this._noiseX = 0;
        this._noiseY = 0;
        this._interval = 0;

        this._speaker = null;
        this._leftTrack = null;
        this._rightTrack = null;
        this._upTrack = null;
        this._downTrack = null;

        this.tick = this.tick.bind(this);
        this.mouseHandler = this.mouseHandler.bind(this);
        this.touchHandler = this.touchHandler.bind(this);

        this._pixiApp = new PIXI.Application({
            forceCanvas: true,
            width: 300,
            height: 600,
            view: canvas,
            antialias: true,
            transparent: true
        });
    }

    setup() {
        this._pixiApp.loader
            .add('speaker', 'assets/speaker.skel')
            .load(this.onAssetsLoaded.bind(this));

        this._pixiApp.ticker.add(this.tick);

        this._interval = setInterval(() => {
            this._noiseX = (Math.random() * 2 - 1) * 0.01;
            this._noiseY = (Math.random() * 2 - 1) * 0.01;
        }, 500);

        document.addEventListener('mousemove', this.mouseHandler);
        document.addEventListener('touchstart', this.touchHandler);
        document.addEventListener('touchmove', this.touchHandler);
    }

    onAssetsLoaded(loader, res) {
        this._speaker = new PIXI.spine.Spine(res.speaker.spineData);
        this._speaker.skeleton.setToSetupPose();
        this._speaker.update(0);

        const container = new PIXI.Container();
        container.addChild(this._speaker);

        const localRect = this._speaker.getLocalBounds();
        this._speaker.position.set(-localRect.x, -localRect.y);

        // now we can scale, position and rotate the container as any other display object
        const scale = Math.min(
            this._pixiApp.screen.width / container.width,
            this._pixiApp.screen.height / container.height * 0.98,
        );
        container.scale.set(scale, scale);
        container.position.set(
            (this._pixiApp.screen.width - container.width) * 0.5,
            (this._pixiApp.screen.height - container.height),
        );

        this._speaker.state.setAnimation(0, 'idle', true);
        this._speaker.state.setAnimation(1, 'blink', true);
        this._speaker.state.setAnimation(2, 'babble', true);

        this._leftTrack = this._speaker.state.setAnimation(3, 'left', true);
        this._leftTrack.mixBlend = PIXI.spine.core.MixBlend.add;
        this._leftTrack.alpha = 0.0;

        this._rightTrack = this._speaker.state.setAnimation(4, 'right', true);
        this._rightTrack.mixBlend = PIXI.spine.core.MixBlend.add;
        this._rightTrack.alpha = 0.0;

        this._upTrack = this._speaker.state.setAnimation(5, 'up', true);
        this._upTrack.mixBlend = PIXI.spine.core.MixBlend.add;
        this._upTrack.alpha = 0.0;

        this._downTrack = this._speaker.state.setAnimation(6, 'down', true);
        this._downTrack.mixBlend = PIXI.spine.core.MixBlend.add;
        this._downTrack.alpha = 0.0;

        this._pixiApp.stage.addChild(container);
        this._pixiApp.start();

        this._hasLoaded = true;
    }

    tick() {
        if (this._hasLoaded) {
            this._speaker.skeleton.setToSetupPose();

            const width = this._canvas.getBoundingClientRect().width;
            const height = this._canvas.getBoundingClientRect().height;

            // compute animation alpha
            const halfWidth = width / 2;
            const verticalThreshold = height / 3;

            const targetHorizValue = Math.min(Math.max(-1, (this._targetX - halfWidth) / halfWidth), 1);
            const targetVertValue = Math.min(Math.max(-1, (this._targetY - verticalThreshold) / verticalThreshold), 1);

            const horizLimit = 0.8;
            const vertLimit = 0.75;

            let currentHorizValue = this._leftTrack.alpha > 0 ? -this._leftTrack.alpha : this._rightTrack.alpha;
            currentHorizValue += (targetHorizValue - currentHorizValue) * 0.08 + this._noiseX;
            currentHorizValue = Math.min(Math.max(-horizLimit, currentHorizValue), horizLimit);

            let currentVertValue = this._upTrack.alpha > 0 ? -this._upTrack.alpha : this._downTrack.alpha;
            currentVertValue += (targetVertValue - currentVertValue) * 0.08;
            currentVertValue = Math.min(Math.max(-vertLimit, currentVertValue), vertLimit);

            if (currentHorizValue < 0) {
                this._leftTrack.alpha = Math.abs(currentHorizValue);
                this._rightTrack.alpha = 0;
            }
            else {
                this._rightTrack.alpha = currentHorizValue;
                this._leftTrack.alpha = 0;
            }

            if (currentVertValue < 0) {
                this._upTrack.alpha = Math.abs(currentVertValue);
                this._downTrack.alpha = 0;
            }
            else {
                this._downTrack.alpha = currentVertValue;
                this._upTrack.alpha = 0;
            }

            this._addNoise = false;
        }
    }

    setPaused(value) {
        value ? this._pixiApp.ticker.stop() : this._pixiApp.ticker.start();
    }

    teardown() {
        clearInterval(this._interval);

        document.removeEventListener('touchstart', this.mouseHandler);
        document.removeEventListener('touchmove', this.touchHandler);
    }

    mouseHandler(e) {
        this._targetX = e.pageX - window.pageXOffset - this._canvas.getBoundingClientRect().x;
        this._targetY = e.pageY - window.pageYOffset - this._canvas.getBoundingClientRect().y;
    }

    touchHandler(e) {
        const event = e.changedTouches[0];
        this._targetX = event.pageX - window.pageXOffset - this._canvas.getBoundingClientRect().x;
        this._targetY = event.pageY - window.pageYOffset - this._canvas.getBoundingClientRect().y;
    }
}

function SpeakerCanvas() {
    const tickerShouldPause = useTickerControl();
    const canvasRef = useRef();
    const speakerControllerRef = useRef();

    useEffect(() => {
        const controller = new SpeakerController(canvasRef.current);
        controller.setup();

        speakerControllerRef.current = controller;

        return () => {
            controller.teardown();
        }
    }, []);

    useEffect(() => {
        if (speakerControllerRef.current) {
            speakerControllerRef.current.setPaused(tickerShouldPause);
        }
    }, [tickerShouldPause]);

    return <canvas ref={canvasRef} id="speaker" style={{
        width: '100%'
    }} />
}


export default function Speaker() {
    return <SpeakerCanvas/>
}