import flagEN from './images/en.png'
import flagMT from './images/mt.png'

const locale = [
    {
        displayName: 'English',
        locale: 'en',
        flag: flagEN
    },
    {
        displayName: 'Malti',
        locale: 'mt',
        flag: flagMT
    }
]

export default locale;