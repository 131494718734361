import Cookies from 'js-cookie'

import DemoState from '../enums/DemoState';
import { resources } from '../i18n';


export const initialState = {
    features: {
        wasm: false,
        audioWorklet: false
    },
    legal: {
        cookieConsent: Number(Cookies.get('cookie_consent')) || 0,
        acceptedTerms: Number(Cookies.get('accepted_terms')) || 0
    },
    demo: {
        state: DemoState.IDLE_WELCOME
    },
    locale: Object.keys(resources).includes(window.localStorage.locale) ? window.localStorage.locale : 'en'
};
