import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { useTranslation } from 'react-i18next';

import LegalDocuments from '../../enums/LegalDocuments';
import { trackEvent } from '../../lib/analytics';
import Waiting from '../Waiting/Waiting';
import { useModalNoScroll } from '../../hooks/modal-noscroll';

import styles from './LegalTextModal.module.scss';
import classNames from 'classnames';


const BASE_POLICY_URL = 'https://bilmalti.s3.eu-central-1.amazonaws.com/policies/latest'
const DEFAULT_HTML = { __html: '' }


const LEGAL_DATA = {
  [LegalDocuments.CookiePolicy]: {
    name: 'cookie',
    url: `${BASE_POLICY_URL}/cookie-policy.html`
  },
  [LegalDocuments.PrivacyPolicy]: {
    name: 'privacy',
    url: `${BASE_POLICY_URL}/privacy-policy.html`
  },
  [LegalDocuments.TermsOfUse]: {
    name: 'terms-of-use',
    url: `${BASE_POLICY_URL}/terms-of-use.html`
  }
}


export const LegalTextModal = ({ closeCallback, show, document }) => {
  const [policyData, setPolicyData] = useState(DEFAULT_HTML);

  const setModalActive = useModalNoScroll();
  useEffect(() => {
    setModalActive(show);
  }, [setModalActive, show]);

  useEffect(() => {
    const currentData = LEGAL_DATA[document];
    if (!currentData) {
      setPolicyData(DEFAULT_HTML);
    }
    else {
      trackEvent(currentData, 'modal_legal');
      axios.get(currentData.url)
        .then((response) => {
          setPolicyData({
            __html: response.data
          });
        })
        .catch((e) => {
          setPolicyData(DEFAULT_HTML);
        });
    }
  }, [document]);

  const { t } = useTranslation();

  return (
    <div className={classNames('modal', 'modal-fx-slideTop', { 'is-active': show })}>
      <div className="modal-background"></div>
      <div className="modal-content large">
        <div className="box">
          {!policyData.__html ? (
            <Waiting style={{
              position: 'absolute',
              left: '50%',
              top: '45%'
            }} />
          ) : null}

          <div className="content">
            <div className={styles.textContainer} dangerouslySetInnerHTML={policyData} />
          </div>
          <footer>
            <div className="field is-grouped is-justify-content-flex-end">
              <p className="control">
                <button className={styles.ctaButton} onClick={closeCallback}>
                  {t('IDS_MODAL_LEGAL_CTA')}
                </button>
              </p>
            </div>
          </footer>
        </div>
      </div>
    </div >
  )
}
