const TRACKING_ID = 'G-9BP8H0G7D6';

const visitDate = new Date();
const events = [];

function initialize() {
    /* eslint-disable */
    window.dataLayer = window.dataLayer || [];
    window.gtag = function () { dataLayer.push(arguments); }
    gtag('js', visitDate);
    gtag('config', TRACKING_ID, { 'anonymize_ip': true, 'allow_google_signals': false });
    for (const eventParams of events) {
        gtag(...eventParams); // send captured events
    }
}

export function disableAnalytics() {
    window[`ga-disable-${TRACKING_ID}`] = true;
    window.gtag = function () {  // capture the events so that they can be sent later if the user gives consent
        events.push(Array.from(arguments));
    };
}

export function enableAnalytics() {
    window[`ga-disable-${TRACKING_ID}`] = false;
    initialize();
}

export function trackEvent(event, category) {
    if (process.env.NODE_ENV === 'production') {
        if (category) {
            gtag('event', event, {
                event_category: category
            })
        }
        else {
            gtag('event', event);
        }
    }
    else {
        console.log(`trackEvent: event: ${event}, category: ${category}`);
    }
}
