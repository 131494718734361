import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useInView } from 'react-intersection-observer';

import { useTranslation } from 'react-i18next';

import styles from './ExplanationBox.module.scss';

import ExplanationBoxCanvas from './ExplanationBoxCanvas';


export default function ExplanationBox(props) {
    const [isHoveredOver, setIsHoveredOver] = useState(false);
    const [windowWidth, setWindowWidth] = useState(-1);

    const { t } = useTranslation();

    const { ref, inView } = useInView({
        rootMargin: '-50% 0px -50% 0px'
    });

    useEffect(() => {
        const resizeHandler = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', resizeHandler);
        resizeHandler();

        return () => {
            window.removeEventListener('resize', resizeHandler);
        }
    });

    const isActive = () => {
        // use scroll position for window width <= 768, otherwise use mouse hovering
        if (windowWidth <= 768) {  // mobile breakpoint
            return inView;
        }
        else {
            return isHoveredOver;
        }
    }

    return (
        <div ref={ref} className={props.className}>
            <div className={classNames(styles.card, { [styles.active]: isActive() })} onMouseOver={() => setIsHoveredOver(true)} onMouseOut={() => setIsHoveredOver(false)}>
                <div className={classNames(styles.heading, 'mb-4')}>
                    <span className={`${styles.stepNo}`}>{props.stepNo}</span>
                    <div className={`${styles.title} is-size-4 is-size-5-tablet is-size-4-desktop`}>{t(props.titleIds)}</div>
                </div>
                <div className={styles.illustration}>
                    <ExplanationBoxCanvas spineFile={props.spineFile} isActive={isActive()} />
                </div>
                <div className={classNames(styles.description, 'is-size-4 is-size-5-tablet is-size-4-desktop')}>{t(props.descriptionIds)}</div>
            </div>
        </div>
    );
}
