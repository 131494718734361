import Cookies from 'js-cookie';

import { enableAnalytics, trackEvent } from '../lib/analytics';
import { supportsAudioWorklet, supportsWebAssembly} from '../lib/helpers';


export const actions = {
    featureDetect: () => {
        const audioWorkletSupport = supportsAudioWorklet();
        if (!audioWorkletSupport) {
            trackEvent('audioworklet-unavailable', 'features');
        }

        const wasmSupport = supportsWebAssembly();
        if (!wasmSupport) {
            trackEvent('wasm-unavailable', 'features');
        }

        return {
            features: {
                wasm: wasmSupport,
                audioWorklet: audioWorkletSupport
            }
        }
    },

    setLocale(state, locale) {
        trackEvent(locale, 'locale');
        window.localStorage.locale = locale;
        return {
            locale
        }
    },

    setCookieConsent(state, value) {
        Cookies.set('cookie_consent', 1, { expires: 365, domain: 'bilmalti.com' });

        if (value && (value !== state.legal.cookieConsent)) {
            enableAnalytics();
        }

        return {
            legal: {
                ...state.legal,
                cookieConsent: value
            }
        }
    },

    setAcceptedTerms(state, value) {
        Cookies.set('accepted_terms', 1, { expires: 365, domain: 'bilmalti.com' });
        return {
            legal: {
                ...state.legal,
                acceptedTerms: value
            }
        }
    },

    setDemoState(state, value) {
        return {
            demo: {
                ...state.demo,
                state: value
            }
        }
    }
}
