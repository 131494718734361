import React, { useState } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { trackEvent } from '../../lib/analytics';

import styles from './MailingListForm.module.scss';
import { ReactComponent as SuccessSignUp } from '../../assets/images/success-signup-icon.svg';

const State = Object.freeze({
  idle: 0,
  sending: 1,
  sent: 2
});

export default function MailingListForm() {
  const [state, setState] = useState(State.idle);
  const { register, handleSubmit, errors } = useForm();

  const { t } = useTranslation();

  const submit = async (data) => {
    if (state === State.idle) {
      setState(State.sending);

      const formData = new FormData();
      formData.append('name', data.name);
      formData.append('email', data.email);

      try {
        const response = await axios({
          method: 'post',
          url: '/signup',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        if (response.status === 200) {
          trackEvent('sign-up', 'mailing-list');
          setState(State.sent);
        }
        else {
          setState(State.idle);
        }
      }
      catch (e) {
        setState(State.idle);
      }
    }
  };

  return (
    <>
      <div className={classNames(
        styles.form,
        { [styles.formSending]: state === State.sending },
        { [styles.formSent]: state === State.sent }
      )}>
        <form onSubmit={handleSubmit(submit)}>
          <div className="field">
            <label className="label">{t('IDS_MAILING_LIST_FIELD_NAME')}</label>
            <div className="control">
              <input className="input" name="name" type="text" ref={register({
                required: 'IDS_MAILING_LIST_REQUIRED_FIELD'
              })} />
            </div>
            {<p className={`help ${styles.validationField}`}>
              {t(errors.name) && t(errors.name.message)}
            </p>}
          </div>

          <div className="field">
            <label className="label">{t('IDS_MAILING_LIST_FIELD_EMAIL')}</label>
            <div className="control">
              <input name="email" className="input" type="text" ref={register({
                required: 'IDS_MAILING_LIST_REQUIRED_FIELD',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'IDS_MAILING_LIST_FIELD_EMAIL_INVALID'
                }
              })} />
            </div>
            {<p className={`help ${styles.validationField}`}>
              {t(errors.email) && t(errors.email.message)}
            </p>}
          </div>

          <button type="submit" className={`${styles.ctaButton} mt-4`}>{t('IDS_MAILING_LIST_CTA')}</button>
        </form>
      </div>

      {state === State.sent && (
        <div className={classNames(
          { [styles.messageSuccess]: state === State.sent }
        )}>
          <SuccessSignUp className={styles.messageSuccessIcon} />
          <h3 className="is-size-5 has-text-weight-bold mt-2">{t('IDS_MAILING_LIST_SUCCESS')}</h3>
        </div>
      )}
    </>
  )
}