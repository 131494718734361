export default function faq() {
    return [
        {
            questionIds: 'IDS_FAQS_QUESTION_1',
            answerIds: 'IDS_FAQS_ANSWER_1'
        },
        {
            questionIds: 'IDS_FAQS_QUESTION_2',
            answerIds: 'IDS_FAQS_ANSWER_2'
        },
        {
            questionIds: 'IDS_FAQS_QUESTION_3',
            answerIds: 'IDS_FAQS_ANSWER_3'
        }
    ];
}
