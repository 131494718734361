import React from 'react';

import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';

import styles from './LegalBanner.module.scss';


const LegalBanner = React.forwardRef(({ cookieConsent, setCookieConsent, acceptedTerms, setAcceptedTerms, showPrivacyPolicy, showTermsOfUse, showCookiePolicy }, ref) => {
    const { t } = useTranslation();

    return (!cookieConsent || !acceptedTerms) && (
        <div ref={ref} className={`${styles.container} has-background-black-ter has-text-white pl-5 pr-5`}>
            <div className="pt-5 pb-5 container">
                <div className="columns">
                    <div className="column">
                        {!cookieConsent && !acceptedTerms ? (
                            <Trans i18nKey="IDS_CONSENT_BANNER_ALL_TEXT">
                                By clicking <strong>Accept</strong>, you agree to our <button className="button is-ghost" onClick={showPrivacyPolicy}>Privacy Policy</button>, <button className="button is-ghost" onClick={showTermsOfUse}>Terms of Use</button> and the use of cookies to enhance your experience. For more details about our usage of cookies check out our <button className="button is-ghost" onClick={showCookiePolicy}>Cookie Policy</button>.
                            </Trans>
                        ) : (
                            <Trans i18nKey="IDS_CONSENT_BANNER_COOKIE_TEXT">
                                By clicking <strong>Accept</strong>, you agree to the use of cookies to enhance your experience. For more details about our usage of cookies check out our <button className="button is-ghost" onClick={showCookiePolicy}>Cookie Policy</button>.
                            </Trans>
                        )}

                    </div>
                    <div className="column is-one-quarter">
                        <button className="button is-primary is-radiusless is-fullwidth" onClick={() => {
                            if (acceptedTerms) {
                                setCookieConsent(1);
                            }
                            else {
                                setCookieConsent(1);
                                setAcceptedTerms(1);
                            }
                        }}>{t('IDS_CONSENT_BANNER_CTA')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default LegalBanner;