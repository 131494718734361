export default function steps() {
    return [
        {
            titleIds: 'IDS_STEPS_TITLE_1',
            descriptionIds: 'IDS_STEPS_DESCRIPTION_1',
            spineFile: 'assets/operator.skel'
        },
        {
            titleIds: 'IDS_STEPS_TITLE_2',
            descriptionIds: 'IDS_STEPS_DESCRIPTION_2',
            spineFile: 'assets/server.skel'
        },
        {
            titleIds: 'IDS_STEPS_TITLE_3',
            descriptionIds: 'IDS_STEPS_DESCRIPTION_3',
            spineFile: 'assets/laptop.skel'
        }
    ];
}
