import React, { useEffect, useRef } from 'react';

import useTickerControl from '../../hooks/tickerControl';

import * as tmpPIXI from 'pixi.js-legacy';
global.PIXI = tmpPIXI;
const PIXI = global.PIXI;


export default function ExplanationBoxCanvas({ className, spineFile, isActive }) {
    const tickerShouldPause = useTickerControl();

    const ref = useRef(null);
    const pixiRef = useRef();
    const pixiAppRef = useRef();

    useEffect(() => {
        const canvas = ref.current;

        let hasLoaded = false;
        let item;

        const pixiApp = new PIXI.Application({
            width: 600,
            height: 600,
            view: canvas,
            antialias: true,
            transparent: true
        });

        pixiAppRef.current = pixiApp;

        pixiApp.loader
            .add('item', spineFile)
            .load((loader, res) => {
                item = new PIXI.spine.Spine(res.item.spineData);
                item.skeleton.setToSetupPose();
                item.update(0);

                pixiRef.current = item;

                const container = new PIXI.Container();
                container.addChild(item);

                const localRect = item.getBounds();
                item.position.set(-localRect.x, -localRect.y);

                // now we can scale, position and rotate the container as any other display object
                const scale = Math.min(
                    pixiApp.screen.width / container.width,
                    pixiApp.screen.height / container.height * 0.98,
                );
                container.scale.set(scale, scale);
                container.position.set(
                    (pixiApp.screen.width - container.width) * 0.5,
                    (pixiApp.screen.height - container.height),
                );

                pixiApp.stage.addChild(container);
                pixiApp.start();

                item.state.setAnimation(0, 'idle', true);

                hasLoaded = true;
            });

        pixiApp.ticker.add(() => {
            if (hasLoaded) {
                item.skeleton.setToSetupPose();
            }
        });
    }, [spineFile]);

    useEffect(() => {
        if (pixiRef.current && isActive) {
            pixiRef.current.state.setAnimation(1, 'active', false);
        }
    }, [isActive])

    useEffect(() => {
        if (pixiAppRef.current) {
            tickerShouldPause ? pixiAppRef.current.ticker.stop() : pixiAppRef.current.ticker.start();
        }
    }, [tickerShouldPause])


    return <canvas ref={ref} className={className} />
}
