export function supportsAudioWorklet() {
    return !(typeof AudioWorkletNode !== 'function' || !('audioWorklet' in AudioContext.prototype));
}

export function supportsWebAssembly() {
    try {
        if (typeof WebAssembly === "object" && typeof WebAssembly.instantiate === "function") {
            const module = new WebAssembly.Module(Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00));
            if (module instanceof WebAssembly.Module) {
                const instance = new WebAssembly.Instance(module) instanceof WebAssembly.Instance;
                if (instance) {
                    return true
                }
            }
        }
    }
    catch (e) { }
    return false;
}

export function supportsMediaRecorder() {
    return !(typeof MediaRecorder !== 'function') && !(typeof MediaRecorder.isTypeSupported !== 'function');
}
