import React, { useState } from 'react';
import classNames from 'classnames';

import { FaAngleDown } from "react-icons/fa";

import localeOptions from '../../locale';
import styles from './LocaleDropdown.module.scss';

export default function LocaleDropdown({ setLocale, currentLocale }) {
    const [isOverTrigger, setIsOverTrigger] = useState(false);
    const [isOverDropdown, setIsOverDropdown] = useState(false);

    const currentLocaleDetails = localeOptions.filter(l => l.locale === currentLocale)[0];

    return (
        <div className={classNames('dropdown', 'is-right', { 'is-active': isOverTrigger || isOverDropdown })}>
            <div className="dropdown-trigger" onMouseOver={() => setIsOverTrigger(true)} onMouseOut={() => setIsOverTrigger(false)}>
                <button className={`button is-small is-rounded ${styles.button} ${styles.trigger}`} aria-haspopup="true" aria-controls="dropdown-menu">
                    <div className={styles.buttonLayout}>
                        <img className={`${styles.flag} ${styles.active}`} src={currentLocaleDetails.flag} alt='' />
                        <span>{currentLocaleDetails.displayName}</span>
                        <FaAngleDown className={styles.dropDownArrow} />
                    </div>
                </button>
            </div>
            <div className={`dropdown-menu ${styles.contentContainer}`} id="dropdown-menu" role="menu" onMouseOver={() => setIsOverDropdown(true)} onMouseOut={() => setIsOverDropdown(false)}>
                <div className="dropdown-content">
                    {localeOptions.map((locale, index) => {
                        return (
                            <a key={index} className="dropdown-item" onClick={(e) => {
                                setLocale(locale.locale);

                                // Hide dropdown
                                setIsOverTrigger(false);
                                setIsOverDropdown(false);
                            }}>
                                <div className={styles.languageItem}>
                                    <img className={styles.flag} src={locale.flag} alt='' />
                                    <span style={{ display: 'flex' }} className={classNames(
                                        'has-text-danger',
                                        { 'has-text-weight-bold': locale.locale === currentLocale }
                                    )} key={index}>{locale.displayName}</span>
                                </div>
                            </a>
                        )
                    })}
                </div>
            </div>
        </div>
    );
}
