import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import styles from './AudioErrorModal.module.scss';
import classNames from 'classnames';
import { useModalNoScroll } from '../../hooks/modal-noscroll';


export function AudioErrorModal({ show }) {
  const { t } = useTranslation();

  const setModalActive = useModalNoScroll();
  useEffect(() => {
    setModalActive(show);
  }, [setModalActive, show]);

  return (
    <div className={classNames('modal', 'modal-fx-slideTop', { 'is-active': show })}>
      <div className="modal-background"></div>
      <div className="modal-content">
        <div className="box">
          <h5 className="title is-5">{t('IDS_NO_MIC_MODAL_TITLE')}</h5>
          <div className="content">{t('IDS_NO_MIC_MODAL_MESSAGE')}</div>
          <footer className="is-flex is-justify-content-flex-end">
            <button className={styles.ctaButton} onClick={() => window.location.reload()}>
              {t('IDS_NO_MIC_MODAL_CTA')}
            </button>
          </footer>
        </div>
      </div>
    </div>
  )
}
