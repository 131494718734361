import React from 'react';

import styles from './Waiting.module.scss';

export default function Waiting(props) {
    return (
        <div className={styles.container} {...props}>
            <span className={styles.dot}></span>
            <span className={styles.dot}></span>
            <span className={styles.dot}></span>
        </div>
    )
}
